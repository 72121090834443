/*Prealoder*/
#preloader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  transition: all 200ms ease;
}
#preloader .spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
}

.preloader-hide {
  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease !important;
}

#page {
  position: relative;
  min-height: 60vh;
}
.page-content {
  overflow-x: hidden;
  transition: all 300ms ease;
  z-index: 90;
  padding-bottom: 80px;
  padding-bottom: calc(80px + (constant(safe-area-inset-bottom)) * 1.1);
  padding-bottom: calc(80px + (env(safe-area-inset-bottom)) * 1.1);
}

.page-title {
  padding-top: calc((constant(safe-area-inset-top)) * 0.8) !important;
  padding-top: calc((env(safe-area-inset-top)) * 0.8) !important;
}

/*Page Titles*/
.page-title {
  display: flex;
  transition: all 300ms ease;
  margin-top: 23px;
  margin-bottom: 30px;
}
.page-title h1 {
  margin-right: auto;
  font-weight: 700 !important;
  font-size: 28px;
  padding-top: 3px;
  margin-left: 15px;
}
.page-title div {
  margin-left: auto;
}
.page-title p {
  font-weight: 500;
  margin-top: -9px;
  margin-bottom: -10px;
  margin-right: 15px;
}
.page-title-icon {
  float: right;
  z-index: 2;
  height: 38px;
  width: 38px;
  margin-right: 13.3333333333px;
  line-height: 39px;
  border-radius: 16px;
  text-align: center;
}
