.menu {
  position: fixed;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 101;
  overflow: scroll;
  transition: all 300ms ease;
  -webkit-overflow-scrolling: touch;
}

.menu-active.menu {
  opacity: 1 !important;
  transition: all 300ms ease;
  pointer-events: all !important;
}
.menu-box-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  opacity: 0;
  pointer-events: none;
  transition: all 200ms ease;
}
.menu-box-modal.menu-active {
  transition: all 200ms ease;
  pointer-events: all !important;
  opacity: 1 !important;
  transform: translate(-50%, -50%) !important;
  display: block !important;
}
.menu-hider.menu-active {
  transition: all 300ms ease;
  opacity: 1;
  pointer-events: all;
}

.menu-active {
  opacity: 1 !important;
}
.menu-hider {
  position: fixed;
  top: -100px;
  bottom: -100px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: all 300ms ease;
}
.menu-box-bottom.menu-active {
  transform: translate3d(0%, 20px, 0) !important;
}
.menu-box-bottom {
  bottom: 0px;
  left: 0px;
  right: 0px;
  transform: translate3d(0, 110%, 0);
}

#menu-install {
  display: block;
}
@media only screen and (min-width: 1025px) {
  .menu {
    order: 1;
    display: block;
    position: relative;
    transform: translate3d(0%, 0, 0) !important;
    width: 400px !important;
    overflow: hidden;
    min-height: 100vh;
  }

  .menu > div:first-child img {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  #menu-install {
    display: none;
  }
}

.logo-header {
  background-image: url('../img/logo-elAbogado.png');
  background-size: cover;
  width: 157px;
}
