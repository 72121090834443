.list-group .badge {
  z-index: 2;
}

.list-custom-large a {
  line-height: 70px;
  color: #1f1f1f;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.list-custom-large a strong {
  font-size: 7px;
  position: absolute;
  font-size: 11px;
  margin-top: 10px;
  color: #adb5bd;
  font-weight: 500;
}
.list-custom-large a i:first-child {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-top: 21px;
  text-align: center;
  float: left;
  margin-right: 15px;
}
.list-custom-large a i:last-child {
  float: right;
  font-size: 10px;
}
.list-custom-large img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.list-custom-large .badge {
  position: absolute;
  right: 10px;
  margin-top: 30px;
  font-size: 9px;
  padding: 5px 8px 5px 8px;
  font-weight: 700;
}
.list-custom-large i {
  line-height: 74px;
}
.list-custom-large .custom-control {
  z-index: 10;
  position: absolute;
  right: 40px;
  margin-top: 22px;
}
.list-custom-large .custom-control input {
  transform: translateY(-12px) !important;
}
