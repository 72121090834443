/*--Header---*/
.header-title span {
  font-family: 'Inter', sans-serif;
}

.header-logo {
  color: rgba(0, 0, 0, 0) !important;
}
.header-logo span {
  display: none;
  display: none;
}

/*Appkit  2.7*/
.header.header-auto-show {
  pointer-events: none;
}

.header.header-active {
  pointer-events: all !important;
}
.header-logo {
  background-size: 28px 28px;
  width: 50px;
}

.header {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  z-index: 99;
  transition: all 300ms ease;
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
}
.header .header-title {
  position: absolute;
  height: 50px;
  line-height: 49px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  z-index: 1;
  font-family: 'Inter', sans-serif;
}
.header .header-logo {
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
}
.header .header-icon {
  position: absolute;
  height: 50px;
  width: 45px;
  text-align: center;
  line-height: 49px;
  font-size: 12px;
  color: #1f1f1f;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
}
.header .dropdown-menu {
  z-index: 99;
}

.header-clear-notch {
  padding-top: calc(0px + (constant(safe-area-inset-top)) * 0.8) !important;
  padding-top: calc(0px + (env(safe-area-inset-top)) * 0.8) !important;
}

.header-clear-small {
  padding-top: calc(20px + (constant(safe-area-inset-top)) * 0.8) !important;
  padding-top: calc(20px + (env(safe-area-inset-top)) * 0.8) !important;
}

.header-clear-medium {
  padding-top: calc(70px + (header-auto-show(safe-area-inset-top)) * 0.8) !important;
  padding-top: calc(70px + (env(safe-area-inset-top)) * 0.8) !important;
}

.header-clear-large {
  padding-top: calc(85px + (constant(safe-area-inset-top)) * 0.8) !important;
  padding-top: calc(85px + (env(safe-area-inset-top)) * 0.8) !important;
}

.header {
  height: calc(50px + (constant(safe-area-inset-top)) * 0.8) !important;
  height: calc(50px + (env(safe-area-inset-top)) * 0.8) !important;
}

.header-icon {
  margin-top: calc((constant(safe-area-inset-top)) * 0.8) !important;
  margin-top: calc((env(safe-area-inset-top)) * 0.8) !important;
}

.header-title {
  margin-top: calc((constant(safe-area-inset-top)) * 0.8) !important;
  margin-top: calc((env(safe-area-inset-top)) * 0.8) !important;
}
.header-demo {
  z-index: 98 !important;
  position: relative;
}
.header-auto-show {
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease;
}

.header-active {
  opacity: 1;
  pointer-events: all;
  transition: all 300ms ease;
}

.header-always-show .header {
  margin-top: 0px !important;
}

.header-static {
  position: relative !important;
}

.header-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
}

.header-clear {
  padding-top: 50px;
}

.header-clear-small {
  padding-top: 20px;
}

.header-clear-medium {
  padding-top: 70px;
}

.header-clear-large {
  padding-top: 85px;
}

.header-icon .badge {
  position: absolute;
  margin-left: -5px;
  color: #fff !important;
  width: 16px;
  text-align: center;
  line-height: 16px;
  padding: 0px;
  padding-left: 1px !important;
  border-radius: 18px;
  margin-top: 7px;
  font-size: 11px;
}

.header-transparent-light {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}
.header-transparent-light a {
  color: #fff;
}

.header-transparent-dark {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}
.header-transparent-dark a {
  color: #000;
}

.header-logo-center .header-icon-1 {
  right: 0px;
}
.header-logo-center .header-icon-2 {
  right: 45px;
}
.header-logo-center .header-icon-3 {
  left: 45px;
}

.header-logo-center .header-title {
  width: 150px;
  left: 50%;
  margin-left: -75px;
  text-align: center;
}
.header-logo-center .header-logo {
  background-position: center center;
  width: 150px !important;
  left: 50%;
  margin-left: -75px;
  text-align: center;
}

.header-logo-left .header-icon-1 {
  right: 0px;
}
.header-logo-left .header-icon-2 {
  right: 45px;
}
.header-logo-left .header-icon-3 {
  right: 90px;
}
.header-logo-left .header-icon-4 {
  right: 135px;
}
.header-logo-left .header-logo {
  left: 0px !important;
  margin-left: 0px !important;
}
.header-logo-left .header-title {
  left: 15px !important;
  margin-left: 0px !important;
}

.header-logo-right .header-icon-1 {
  left: 0px;
}
.header-logo-right .header-icon-2 {
  left: 45px;
}
.header-logo-right .header-icon-3 {
  left: 90px;
}
.header-logo-right .header-icon-4 {
  left: 135px;
}
.header-logo-right .header-logo {
  left: auto !important;
  right: 20px !important;
  margin-left: 0px !important;
}
.header-logo-right .header-title {
  left: auto !important;
  right: 20px !important;
  margin-left: 0px !important;
}

.header-logo-app .header-icon-1 {
  right: 0px;
}
.header-logo-app .header-icon-2 {
  left: 2px;
}
.header-logo-app .header-icon-3 {
  left: 45px;
}
.header-logo-app .header-icon-4 {
  left: 90px;
}
.header-logo-app .header-logo {
  right: 0px !important;
  margin-right: 40px !important;
}
.header-logo-app .header-title {
  right: 0px !important;
  margin-right: 50px !important;
}
.header-logo-center .header-icon-1 {
  left: 0px;
}
.header-logo-center .header-icon-4 {
  right: 0px;
}
