.btn,
input,
select {
  box-shadow: none !important;
  outline: 0px !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none !important;
  opacity: 0.65 !important;
  display: block !important;
}
.btn-m {
  padding: 13px 20px !important;
}
.btn-xxs,
.btn-xs,
.btn-s,
.btn-sm,
.btn-m,
.btn-l {
  font-size: 1rem !important;
}
.btn-full {
  display: block;
}
.btn {
  font-family: 'Inter', sans-serif;
  transition: all 300ms ease;
  color: #fff;
}
.btn-xs {
  padding: 7px 14px !important;
}
.btn-xl {
  padding: 15px 30px !important;
}
.btn-l {
  padding: 14px 26px !important;
}
.btn-full {
  display: block;
}
.btn-sm {
  padding: 10px 18px !important;
}

.verificationCode {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
  display: flex;
  justify-content: center;
}
.verificationCode .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
}

.verificationCode .ReactInputVerificationCode__item,
.verificationCode .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.verificationCode .ReactInputVerificationCode__item:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #ebebeb;
  transition: background-color 0.2s ease-out;
}

.verificationCode .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}
