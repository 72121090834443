.menu-box-left {
  left: 0px;
  top: 0px !important;
  width: 250px;
  height: 100%;
  bottom: 0px;
  transform: translate3d(-100%, 0, 0);
}
.menu-box-left.menu-active {
  transform: translate3d(0%, 0, 0) !important;
}

.menu-divider {
  padding-left: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.list-custom-small {
  line-height: 50px;
}

.list-menu {
  font-family: 'Inter', sans-serif;
  margin: 0px 25px 0px 10px;
}

.list-custom-small a {
  color: #1f1f1f;
  font-weight: 600;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.list-menu a {
  border: none !important;
}
.menu .list-custom-small a i:first-child {
  margin-left: 6px;
}
.list-custom-small i {
  line-height: 50px;
}
.list-custom-small a i:first-child {
  margin-left: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
  float: left;
  margin-right: 10px;
}
.list-custom-small a i:last-child {
  float: right;
  font-size: 10px;
  opacity: 0.5;
}

.list-menu a i:first-child {
  border-radius: 12px;
  height: 32px !important;
  line-height: 33px !important;
  width: 32px !important;
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 2%);
}
.list-menu span {
  font-weight: 500;
  padding-left: 7px;
  color: #7e8793;
}
