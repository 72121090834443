/*-Footer Menu-*/
#footer-bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 98;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  min-height: 61px;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 350ms ease !important;
}
#footer-bar a {
  color: #1f1f1f;
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto;
}
#footer-bar a span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -6px;
  opacity: 0.7;
  font-family: 'Roboto', sans-serif !important;
}
#footer-bar a i {
  font-size: 1.3rem;
  position: relative;
  z-index: 2;
}
#footer-bar a svg {
  transform: translateY(-6px);
}
#footer-bar .badge {
  font-style: normal;
  z-index: 5;
  top: 0px;
  position: absolute;
  margin-left: 3px;
  color: #fff !important;
  width: 18px;
  text-align: center;
  line-height: 18px;
  padding: 0px;
  padding-left: 0px !important;
  border-radius: 18px;
  margin-top: 7px;
  font-size: 11px;
}
.footer .footer-logo {
  background-size: 80px 80px;
  height: 80px !important;
  margin-bottom: 20px !important;
}
.footer-bar-6 .circle-nav strong,
.footer-bar-6 .active-nav em {
  background-image: linear-gradient(to bottom, #5d9cec, #4a89dc) !important;
}
.footer-bar-6 u {
  background-color: rgba(255, 255, 255, 0.2);
}

.footer-bar-6 {
  margin-left: -3px;
}
.footer-bar-6 span {
  font-size: 0.8rem !important;
}
.footer-bar-6 i {
  transform: translateY(-2px);
  opacity: 0.7;
}
.footer-bar-6 .circle-nav strong {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  left: 50%;
  top: -23px;
  z-index: 0;
  transform: translateX(-50%);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09);
  animation: footerAni 1s infinite;
  background-image: linear-gradient(to bottom, #1b55bf 0%, #1740bf 100%) !important;
}
@keyframes footerAni {
  0% {
    transform: scale(1, 1) translateX(-50%);
  }
  50% {
    transform: scale(1.05, 1.05) translateX(-48%);
  }
}
.footer-bar-6 .circle-nav span {
  font-size: 1rem !important;
  margin-top: -8px !important;
}
.footer-bar-6 .circle-nav i {
  transform: translateY(-21px) translateX(0px);
  color: #fff !important;
  opacity: 1 !important;
  font-size: 20px !important;
  position: relative;
  z-index: 2;
}
.footer-bar-6 .circle-nav svg {
  width: 25px !important;
  height: 25px !important;
  stroke: #fff !important;
  fill: rgba(255, 255, 255, 0.1) !important;
  transform: translateY(-25px) translateX(-1px) !important;
  position: relative;
  z-index: 2;
}
.footer-bar-6 .circle-nav u {
  position: absolute;
  left: 0px;
  right: 0px;
  width: 50px;
  border-radius: 50px;
  height: 50px;
  z-index: 0;
}
.footer-bar-6 .active-nav em {
  position: absolute;
  width: 60px;
  height: 4px;
  border-radius: 60px;
  left: 50%;
  top: 57px;
  transform: translateX(-50%);
  background-image: linear-gradient(to bottom, #1b55bf 0%, #1740bf 100%) !important;
}

.box-privacy {
  font-size: 9px;
  color: #1f1f1f;
  text-align: center;
}
.politicaPriv {
  font-size: 0.8rem;
}
@media only screen and (min-width: 1025px) {
  .footer-bar-6 {
    display: none !important;
  }
  .politicaPriv {
    font-size: smaller;
  }
}

@media only screen and (max-width: 390px) {
  .politicaPriv {
    font-size: smaller;
  }
}
