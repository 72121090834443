.card {
  -webkit-transform: translate3d(0, 0, 0);
  background-size: cover;
  border: 0px !important;
  max-height: 150000px;
  margin-bottom: 30px;
  background-position: center center !important;
}

.card-style {
  overflow: hidden;
  margin: 0px 15px 30px 15px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
}

.card-top {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}
.card-bottom {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.card-center {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.card-fixed {
  position: fixed;
  left: 0px;
  right: 0px;
}

.card-overlay {
  background-position: center center;
  background-size: cover !important;
}

.card-overlay {
  background-position: center center;
  background-size: cover !important;
}
.card-overlay {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1;
}
.card-clear {
  pointer-events: none;
  margin-bottom: -30px;
  opacity: 0;
}

.card-mw-500 {
  max-width: 500px;
}

@media only screen and (min-width: 1025px) {
  .card-top {
    display: none;
  }
  .card-fixed {
    position: relative;
    max-height: 200px !important;
    overflow: hidden;
    margin: 15px 0px 10px 0px;
    border-radius: 20px;
    border: none;
  }
  .card-clear {
    display: none;
  }
  #cardCasos {
    display: block;
  }
}
