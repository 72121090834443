@charset "UTF-8";
/*Typography Settings*/
body {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  line-height: 24px !important;
  color: #727272 !important;
  background-color: #eef2f1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #1f1f1f;
  font-optical-sizing: auto;
  margin-bottom: 5px;
  letter-spacing: -0.8px;
}

h1 {
  line-height: 30px;
  padding-bottom: 5px;
}

h2 {
  line-height: 25px;
}

h3 {
  line-height: 24px;
}

h4 {
  line-height: 25px;
}

h5 {
  line-height: 25px;
}

a {
  text-decoration: none !important;
}

p {
  color: #727272;
}

/*Page Stucture Settings*/
body {
  min-height: 60vh;
}
input {
  font-size: 1rem !important;
}
.theme-light .color-theme {
  color: #0f1117 !important;
}

.content {
  margin: 20px 15px 20px 15px;
}
.disabled {
  display: none !important;
}
/*border radius*/
.rounded-m {
  border-radius: 20px !important;
}
.rounded-sm {
  border-radius: 12px !important;
}
.rounded-xl {
  border-radius: 50px !important;
}
.rounded-s {
  border-radius: 8px !important;
}

/*border*/

.border-0 {
  border: 0 !important;
}
.border-s {
  border: solid 3px !important;
}
/*border color*/
.border-gray-light {
  border-color: #e2e5ea !important;
}
.border-highlight {
  border-color: #4a89dc !important;
}

/*Font sizes*/
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-36 {
  font-size: 36px !important;
}
.font-40 {
  font-size: 40px !important;
}

/*Global Colors*/

.color-white {
  color: #fff !important;
}
.color-highlight {
  color: #4a89dc;
}
.color-green-light {
  color: #a0d468 !important;
}
.color-blue-light {
  color: #5d9cec !important;
}
.color-yellow-light {
  color: #ffce54 !important;
}
.color-red-light {
  color: #ed5565 !important;
}
.color-teal-light {
  color: #a0cecb !important;
}
.color-gray-dark {
  color: #aab2bd !important;
}
.color-green-dark {
  color: #8cc152 !important;
}
.color-blue-dark {
  color: #4a89dc !important;
}
.color-red-dark {
  color: #da4453 !important;
}
.color-highlight {
  color: #4a89dc !important;
}
.color-yellow-dark {
  color: #f6bb42 !important;
}
.color-black {
  color: #000 !important;
}
.color-brown-dark {
  color: #aa8e69 !important;
}
/*Icons*/
.icon {
  display: inline-block;
  position: relative;
  text-align: center;
}
.icon-m i {
  width: 45px;
  line-height: 45px;
  font-size: 16px;
}
.icon-50 {
  width: 50px;
}
.icon-xs i {
  width: 38px;
  line-height: 38px;
  font-size: 14px;
}
/*font weight*/
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-900 {
  font-weight: 900;
}
/*gradients*/
.gradient-blue {
  background-image: linear-gradient(to bottom, #5d9cec, #4a89dc);
}
.gradient-green {
  background-image: linear-gradient(to bottom, #a0d468, #8cc152);
}
.gradient-highlight {
  background-image: linear-gradient(to bottom, #5d9cec, #4a89dc) !important;
  color: #fff;
}
.gradient-red {
  background-image: linear-gradient(to bottom, #ed5565, #da4453);
}
.gradient-yellow {
  background-image: linear-gradient(to bottom, #ffce54, #f6bb42);
}
.gradient-magenta {
  background-image: linear-gradient(to bottom, #ac92ec, #967adc);
}
.gradient-teal {
  background-image: linear-gradient(to bottom, #a0cecb, #7db1b1);
}
/*Opacity*/
.opacity-30 {
  opacity: 0.3 !important;
}
.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

/*float*/

.float-end {
  float: right !important;
}

/* Margin */

.ms-n3 {
  margin-left: -1rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.ml-3 {
  margin-right: 1rem !important;
}
/* Padding */
.pl-2 {
  padding-left: 0.25rem !important;
}

/*line height*/

.line-height-s {
  line-height: 17px !important;
}

.line-height-xl {
  line-height: 37px !important;
}
.line-height-l {
  line-height: 26px !important;
}

/*divider*/
.divider {
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

/*Backgrounds*/

.bg-6 {
  background-image: url('https://www.panel.legal/usuarios/images/pictures/6.jpg');
}

.bg-blue-dark {
  background-color: #4a89dc !important;
  color: #fff !important;
}

.bg-gradient {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0.1) 30%,
    rgba(0, 0, 0, 0.8) 70%,
    black 100%
  ) !important;
}

.bg-green-dark {
  background-color: #8cc152 !important;
  color: #fff !important;
}

.bg-highlight {
  background-color: #4a89dc !important;
  color: #fff !important;
}

.bg-red-dark {
  background-color: #da4453 !important;
  color: #fff !important;
}
.bg-dark-dark {
  background-color: #434a54 !important;
  color: #fff !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

/*Shadow*/
.shadow-xl {
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 2%) !important;
}

.content p:last-child {
  margin-bottom: 0px;
}

.divider {
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

/* Image logo source https://www.flaticon.com/search?author_id=168&style_id=1227&type=standard*/

.logo-login {
  background-image: url('../img/log-in.png');
  background-size: cover;
  animation: fade 1s;
}

.logo-send-email {
  background-image: url('../img/send.png');
  background-size: cover;
  animation: fade 2s;
}

.wh-150 {
  width: 150px;
  height: 150px;
}

.boxed-text-xl {
  width: 90%;
  max-width: 340px;
}

.boxed-text-xl,
.boxed-text-l,
.boxed-text-m,
.boxed-text-s {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 390px) {
  body {
    font-size: calc(0.75em + 1vw);
  }
}

@media only screen and (min-width: 1025px) {
  main {
    order: 2;
    width: 100%;
    text-align: center;
  }

  main #page {
    width: 75%;
    margin: auto;
    text-align: left;
  }

  #root {
    display: flex;
  }

  .page-title {
    opacity: 0;
  }
}
