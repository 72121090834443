.new-lead-modal {
  display: block;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 1025px) {
  .new-lead-modal {
    display: none;
    top: 50vh;
    width: 100%;
    height: 100%;
    width: 75%;
    margin: auto;
    text-align: left;
    position: absolute;
    width: 100% !important;
    cursor: pointer;
    text-align: center;
  }
  .new-lead-modal h4 {
    font-size: 2em;
    margin-bottom: 30px;
  }

  .new-lead-modal .font-18 {
    font-size: 2em;
  }
}
