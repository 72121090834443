.input-style {
  position: relative;
  margin-bottom: 15px !important;
}
.input-style.has-borders input,
.input-style.has-borders select,
.input-style.has-borders textarea {
  height: 53px;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-top-width: 1px !important;
  padding-left: 13px !important;
  padding-right: 10px !important;
  border-radius: 10px !important;
}

.input-style input,
.input-style select,
.input-style textarea {
  font-size: 12px;
  width: 100%;
  line-height: 45px;
  height: 45px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  padding: 0px;
  border-radius: 0px;
}
.theme-light input,
select,
textarea {
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.theme-light input,
select,
textarea {
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.input-style.has-borders.input-style-always-active label {
  opacity: 1;
  left: 23px !important;
  transform: translateX(-14px) !important;
  margin-left: 0px !important;
  position: absolute;
  padding: 0px 5px !important;
  height: 23px;
  font-size: 12px;
  transition: all 250ms ease;
  background-color: #fff;
}
.input-style.has-icon.has-borders input,
.input-style.has-icon.has-borders select,
.input-style.has-icon.has-borders textarea {
  padding-left: 35px !important;
}
.input-style label {
  opacity: 0;
  left: 0px !important;
  transform: translateX(30px);
  position: absolute;
  top: -12px;
  height: 10px;
  font-size: 10px;
  transition: all 150ms ease;
  background-color: #fff;
}
.input-style.has-borders .valid,
.input-style.has-borders .invalid,
.input-style.has-borders span,
.input-style.has-borders em {
  margin-right: 10px;
}

.input-style em,
.input-style span,
.input-style .valid,
.input-style .invalid {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 5px;
  pointer-events: none;
}
.input-style.has-borders .valid,
.input-style.has-borders .invalid,
.input-style.has-borders span,
.input-style.has-borders em {
  margin-right: 10px;
}

.input-style em,
.input-style span,
.input-style .valid,
.input-style .invalid {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 5px;
  pointer-events: none;
}
.input-style em,
.input-style span {
  margin-top: -12px;
  font-size: 10px;
  font-style: normal;
  opacity: 0.3;
}

.input-style textarea {
  line-height: 32px !important;
  padding-top: 10px !important;
  transition: all 250ms ease !important;
}
.input-style textarea:focus {
  height: 150px;
  transition: all 250ms ease !important;
}
.input-style textarea:focus {
  line-height: 24px !important;
}

.input-style.has-icon.has-borders i:first-child {
  position: absolute;
  top: 50%;
  width: 10px;
  padding-left: 15px !important;
  text-align: center;
  margin-top: -7px;
}

textarea:focus {
  outline: none;
}

.ios-switch {
  margin-right: -32px;
  margin-top: 12px !important;
  position: relative;
  padding-left: 10px;
}
.ios-switch label::before {
  content: '';
  display: block !important;
  background-color: #e8e9ea;
  pointer-events: all;
  margin-top: -5px;
  margin-left: -1px;
  width: 58px !important;
  height: 32px !important;
  border: solid 1px rgba(152, 152, 152, 0.4);
  border-radius: 50px !important;
  transition: all 250ms ease;
}
.ios-switch label::after {
  content: '';
  display: block !important;
  margin-top: -31px;
  z-index: 6;
  width: 30px !important;
  height: 30px !important;
  transition: all 250ms ease;
  border-radius: 50px !important;
  background-color: #fff;
  border: solid 1px rgba(152, 152, 152, 0.4);
  transition: all 250ms ease;
}
.ios-input:checked ~ .custom-control-label::before {
  background-color: #8cc152 !important;
}
.ios-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(26px) !important;
}
.ios-switch input,
.android-input input {
  position: absolute !important;
  transform: translateX(-15000px) !important;
}

.ios-switch input {
  display: none;
}
.small-switch {
  transform: scale(0.8, 0.8);
}
.custom-control * {
  cursor: pointer;
  user-select: none !important;
}

.list-custom-large a {
  line-height: 70px;
  color: #1f1f1f;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.search-box {
  line-height: 50px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  background-color: #fff !important;
}
.shadow-xl {
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 2%) !important;
}
.search-box .fa-search {
  position: absolute;
  left: 0px;
  line-height: 52px;
  padding: 0px 15px 0px 30px;
}
.search-box input {
  outline: none !important;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 40px;
  line-height: 45px;
}
